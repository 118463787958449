import { createTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import colors from './colors';

const font = 'Flama-Book';
const fontBold = 'Flama-Semibold';
const breakpoints = createBreakpoints({});

const makeShadows = () => {
  const original = createTheme();
  return {
    ...original,
    shadows: original.shadows.map(() => '0 0 12px 0 rgba(0, 0, 0, 0.16)'),
  };
};

// Create Material UI theme
const defaultMuiTheme = createTheme({
  ...makeShadows(),
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: colors.background.default,
    },
    black: {
      main: colors.black.main,
    },
    primary: {
      main: colors.primary.main,
    },
    secondary: {
      main: colors.secondary.main,
    },
    interactive: {
      main: colors.interactive.main,
    },
    success: {
      main: colors.success.main,
    },
    gray: {
      main: colors.gray.main,
      dark: colors.gray.dark,
      light: colors.gray.light,
      darkBorder: colors.gray.darkBorder,
    },
    error: {
      main: colors.error.main,
    },
    text: {
      primary: '#000',
      secondary: '#fff',
    },
    warning: {
      main: colors.warning.main,
      icon: colors.warning.icon,
    },
    alternative: {
      main: colors.alternative.main,
      contrast: colors.alternative.contrast,
    },
  },
  props: {
    MuiPaper: {
      elevation: 3,
    },
  },
  typography: {
    body1: {
      fontFamily: font,
      lineHeight: 1.6,
      fontSize: 18,
      [breakpoints.down('md')]: {
        fontSize: 17,
        lineHeight: 1.5,
      },
    },
    body2: {
      fontFamily: font,
      fontSize: 14,
      lineHeight: 1.8,
      [breakpoints.down('md')]: {
        fontSize: 14,
        lineHeight: 1.6,
      },
    },
    h1: {
      fontFamily: fontBold,
      fontSize: 65,
      lineHeight: 1.2,
      fontWeight: 600,
      [breakpoints.down('md')]: {
        fontSize: 32,
      },
    },
    h2: {
      fontFamily: fontBold,
      fontSize: 60,
      fontWeight: 600,
      lineHeight: 1.3,
      [breakpoints.down('md')]: {
        fontSize: 30,
      },
    },
    h3: {
      fontFamily: fontBold,
      fontSize: 45,
      fontWeight: 600,
      lineHeight: 1.3,
      [breakpoints.down('md')]: {
        fontSize: 27,
      },
    },
    h4: {
      fontFamily: fontBold,
      fontSize: 35,
      lineHeight: 1.2,
      fontWeight: 600,
      [breakpoints.down('md')]: {
        fontSize: 25,
      },
    },
    h5: {
      fontFamily: fontBold,
      fontSize: 30,
      lineHeight: 1.2,
      fontWeight: 600,
      [breakpoints.down('md')]: {
        fontSize: 23,
      },
    },
    h6: {
      fontFamily: fontBold,
      fontSize: 25,
      lineHeight: 1.2,
      fontWeight: 600,
      [breakpoints.down('md')]: {
        fontSize: 21,
      },
    },
    subtitle1: {
      fontFamily: font,
      fontSize: 22,
      lineHeight: 1.8,
      [breakpoints.down('md')]: {
        fontSize: 19,
        lineHeight: 1.3,
      },
    },
    subtitle2: {
      fontFamily: font,
      fontWeight: 'normal',
      fontSize: 18,
      lineHeight: 1.8,
      [breakpoints.down('md')]: {
        fontSize: 17,
        lineHeight: 1.5,
      },
    },
    caption: {
      fontFamily: font,
      fontSize: 13,
      lineHeight: 1.8,
      [breakpoints.down('md')]: {
        fontSize: 13,
      },
    },
  },
  shape: {
    borderRadius: 3,
  },
  overrides: {
    MuiAlert: {
      message: {
        fontSize: 14,
      },
    },
    MuiRadio: {
      root: {
        color: colors.gray.darkBorder,
      },
      colorSecondary: {
        '&.Mui-checked': {
          color: colors.success.main,
        },
      },
    },
    MuiButton: {
      root: {
        maxHeight: 40,
        fontSize: 13,
        fontFamily: fontBold,
        textTransform: 'none',
        fontWeight: '600',
        [breakpoints.down('sm')]: {
          maxHeight: 40,
        },
      },
      contained: {
        boxShadow: 'none',
        '&.Mui-disabled': {
          opacity: '50%',
        },

      },
      containedPrimary: {
        backgroundColor: colors.primary.main,
        '&.Mui-disabled': {
          opacity: '50%',
          color: '#FFF',
          backgroundColor: colors.primary.main,
        },
        '&:hover': {
          backgroundColor: colors.primary.main,
          '@media (hover: none)': {
            backgroundColor: colors.primary.main,
          },
        },
      },
      containedSecondary: {
        backgroundColor: colors.warning.main,
        '&:hover': {
          backgroundColor: colors.warning.main,
          '@media (hover: none)': {
            backgroundColor: colors.warning.main,
          },
        },
        '&.Mui-disabled': {
          opacity: '50%',
          color: '#FFF',
          backgroundColor: colors.error.main,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.secondary.picker,
      },
    },
    MuiTabs: {
      indicator: {
        height: 3,
      },
    },
    MuiTab: {
      textColorPrimary: {
        fontFamly: 'Lato-Black',
        textTransform: 'initial',
        color: colors.gray.dark,
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: '#FFFFFF',
        color: colors.secondary.picker,
      },
    },
    MuiTableSortLabel: {
      root: {
        fontSize: 14,
        '&$active': {
          color: 'white',
        },
      },
      icon: {
        fontSize: 16,
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.gray.main,
        '&$active': {
          color: 'white',
        },
        '&$checked': {
          color: colors.interactive.main,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: colors.interactive.main,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.gray.dark,
        '& $focused': {
          color: colors.interactive.main,
        },
      },
    },
    MuiInputLabel: {
      filled: {
        transform: 'translate(11px, 16px) scale(1)',
        '&$shrink': {
          transform: 'translate(11px, 7px) scale(0.75)',
        },
      },
      root: {
        transform: 'translate(11px, 16px) scale(1)',
        '&.Mui-focused': {
          color: '#369e3b !important',
        },
        '&$shrink': {
          transform: 'translate(11px, 7px) scale(0.75)',
        },
        '&.Mui-error': {
          color: '#B1B1B1',
        },
      },
      formControl: {
        transform: 'translate(11px, 24px) scale(1)',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: colors.gray.main,
        '&$focused': {
          backgroundColor: colors.gray.main,
        },
        '&:hover': {
          backgroundColor: colors.gray.main,
        },
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid ${colors.interactive.main}`,
        },
      },
      input: {
        padding: '13px 12px 0px 2px',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${colors.interactive.main}`,
        },
      },
      input: {
        padding: '13px 12px 0px 2px',
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '3px',
        '&$focused $notchedOutline': {
          borderColor: '#369e3b !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.gray.dark,
        },
        '&$error $notchedOutline': {
          borderColor: colors.gray.dark,
        },
      },
      input: {
        padding: 6,
      },
    },
    // MuiLink: {
    //   root: {
    //     '&:hover': {
    //       color: colors.interactive.main,
    //     },
    //   },
    // },
    MuiInputBase: {
      root: {
        padding: 6,
        underline: {
          '&:after': {
            borderBottom: `2px solid ${colors.interactive.main}`,
          },
        },
      },
      input: {
        '&::placeholder': {
          textTransform: 'none !important',
        },
        textTransform: 'inherit',
      },
    },
    MuiTableRow: {
      root: {
        '&&.Mui-selected': {
          backgroundColor: colors.interactive.main,
          color: '#FFF',
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 14,
        padding: '16px 0 16px 8px',
        '&:first-child': {
          paddingLeft: 16,
        },
        '&:last-child': {
          paddingRight: 16,
        },
        [breakpoints.only('xs')]: {
          '&:first-child': {
            paddingLeft: 8,
          },
          '&:last-child': {
            paddingRight: 8,
          },
        },
      },
      body: {
        color: 'inherit',
      },
      head: {
        backgroundColor: colors.gray.light,
        color: colors.gray.text,
        borderBottom: 0,
        lineHeight: '1.0rem',
        padding: '16px 0 16px 8px',
      },
    },
    MuiDialog: {
      paper: {
        margin: 16,
      },
      paperFullWidth: {
        width: 'calc(100% - 32px)',
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: colors.alternative.main,
        padding: 8,
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
        fontSize: 20,
      },
    },
    MuiSvgIcon: {
      root: {
      },
    },
    MuiStepConnector: {
      root: {
        '&$disabled': {
          '& $line': {
            borderColor: colors.gray.dark,
          },
        },
      },
      line: {
        borderColor: colors.gray.dark,
      },
      lineHorizontal: {
        margin: '6px 18px 2px 18px',
        borderTopWidth: 2,
      },
      alternativeLabel: {
        top: 6,
        left: 'calc(-50%)',
        right: 'calc(50%)',
      },
      active: {
        '& $line': {
          borderColor: colors.alternative.contrast,
        },
      },
      completed: {
        '& $line': {
          borderColor: colors.alternative.contrast,
        },
      },
    },
    MuiTextField: {
      root: {
        minHeight: 44,
        backgroundColor: colors.secondary.main,
      },
    },
    MuiStepIcon: {
      root: {
        fontSize: 28,
        '&$active': {
          color: colors.alternative.main,
          border: `2px solid ${colors.alternative.contrast}`,
          borderRadius: '50%',
        },
        '&$completed': {
          color: colors.alternative.main,
          borderRadius: '50%',
          backgroundColor: colors.alternative.contrast,
          border: `2px solid ${colors.alternative.contrast}`,
        },
        '&$text': {
          color: colors.alternative.contrast,
          backgroundColor: colors.alternative.contrast,
        },
      },
    },
    MuiStepLabel: {
      label: {
        marginTop: '8px !important',
        color: 'white !important',
      },
      iconContainer: {
        zIndex: 1,
      },
    },
    MuiListItem: {
      root: {
        '&$.Mui-selected': {
          '&$hover': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiListItemAvatar: {
      root: {
        minWidth: 0,
        marginRight: 8,
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: colors.secondary.main,
      },
    },
    MuiListItemText: {
      secondary: {
        color: '#000',
      },
    },
    MuiInputAdornment: {
      root: {
        positionStart: {
          color: colors.gray.dark,
        },
      },
    },
  },
});

export default defaultMuiTheme;
