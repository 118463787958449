/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ApolloProvider } from '@apollo/client';
import defaultMuiTheme from '../src/styles/Default';
import apolloClient from '../lib/apolloClient';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { initGA, logPageView, logTimeOnPage } from '../lib/analytics';

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      logPageView();
    };
    const handleBeforeUnload = () => {
      logTimeOnPage();
    };
    initGA();
    logPageView();
    router.events.on('routeChangeComplete', handleRouteChange);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <title>Widerøe Ground Handling</title>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css" />
        <link rel="stylesheet" href="/fonts.css" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="description" content="Widerøe Ground Handling AS (WGH) provide ground handling services at 41 airports throughout Norway. We promise to take well care of the ground handling for you!" />
      </Head>
      <ThemeProvider theme={defaultMuiTheme}>
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default MyApp;
