import ReactGA from 'react-ga4';

let startTime = new Date();

export const initGA = () => {
  ReactGA.initialize('G-K5X0EF6F2S');
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  startTime = new Date(); // Reset the start time for each page
};

export const logTimeOnPage = () => {
  const endTime = new Date();
  const timeSpent = endTime - startTime;

  ReactGA.event('event', {
    category: 'page_duration',
    action: window.location.pathname,
    label: window.location.pathname,
    value: timeSpent,
  });
};
